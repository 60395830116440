<template>
  <v-container id="home" class="py-8 px-0">
    <BannerPage icon="mdi-account-circle" :title="$t('message.home_title')"></BannerPage>
    <v-skeleton-loader type="article" tile :loading="homePageLoading">
      <v-row class="welcomeMessage_row">
        <v-col cols="12">
          <span class="welcomeMessage_big" v-html="$t('message.welcome_big_text')"></span>&nbsp;
          <span v-if="welcomeMessage" class="welcomeMessage_firstName" v-html="welcomeMessage.first_name"></span>&nbsp;
          <span v-if="welcomeMessage" class="welcomeMessage_lastName" v-html="welcomeMessage.last_name"></span>&nbsp;
          <span v-if="welcomeMessage" class="welcomeMessage_company" v-html="welcomeMessage.company_name"></span>&nbsp;
          <span class="welcomeMessage_small" v-html="$t('message.welcome_small_text')"></span>
        </v-col>
      </v-row>

      <v-row v-if="newInvoices" class="invoices_row">
        <v-col cols="12">
          <v-card tile flat class="border">
            <v-card-title class="invoices_title">
              <v-icon x-large class="invoices_title_icon secondaryColorTx pr-3">mdi-folder</v-icon>
              <span class="invoices_title_text" v-html="$t('message.new_invoice_advice_header')"></span>
            </v-card-title>
            <v-card-text class="invoices_text">
                <span class="invoices_text_advice" v-html="$t('message.new_invoice_advice_unchecked')"></span>
                <router-link class="pa-0 d-flex align-center" to="/invoices">
                  <span class="invoices_text_link" v-html="$t('message.new_invoice_link_text')"></span>
                </router-link>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="messages" class="messages_row">
        <v-col cols="12">
          <v-card tile flat class="border">
            <v-card-title class="messages_title">
              <v-icon x-large 
                class="messages_title_icon secondaryColorTx pr-3">mdi-email-mark-as-unread</v-icon>
              <v-icon v-if="messages.unreadMessages > 0"
                class="messages_title_icon_alert secondaryColorTx pr-1">mdi-alert-circle</v-icon>
              <span class="messages_title_text" v-html="$t('message.message_header')"></span>
            </v-card-title>
            <v-card-text class="messages_text">
                <span v-if="messages.unreadMessages == 0" 
                  class="messages_text_advice" v-html="$t('message.new_message_no_new')"></span>
                <div v-else>
                  <span class="messages_text_advice" v-html="$t('message.new_message_not_read_advice')"></span>
                  <span class="messages_text_counter">&nbsp;&nbsp;({{messages.unreadMessages}})</span>
                </div>
                <v-row v-if="messages.unreadMessages > 0">
                  <v-col cols="12">
                    <router-link class="pa-0 d-flex align-center" to="/messages">
                      <span class="messages_text_link" v-html="$t('message.new_message_link_text')"></span>
                    </router-link>
                  </v-col>
                </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="messagesHome_row">
        <v-col cols="12">
          <v-expansion-panels flat v-if="messagesHome && messagesHome.length > 0" multiple :value="expanded">
            <v-expansion-panel v-for="(messageHome, i) in messagesHome" :key="'msgHome-' + i" class="border my-2">
              <v-expansion-panel-header class="justify-self-start px-4">
                <v-card-title class="d-flex align-center flex-nowrap pa-0">
                  <v-icon x-large class="messagesHome_title_icon secondaryColorTx mr-4">
                    mdi-email-mark-as-unread
                  </v-icon>
                  <span class="messagesHome_title_object" v-html="messageHome.message_object"/>
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="messageHome.message_body" class="messagesHome_text">
                <span class="messagesHome_text_body" v-html="messageHome.message_body"></span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-skeleton-loader>
    <Snackbar ref="mySnackbar"></Snackbar> 
  </v-container>
</template>

<script>
import BannerPage from './BannerPage.vue';
import Snackbar from './Snackbar.vue';
import { services } from "../scripts/services/serviceBuilder";

export default {
  name: 'home',
  components: {
    BannerPage,
    Snackbar
	},
  data: () => ({
    newInvoices: null,
    messages: null,
    messagesHome: null,
    welcomeMessage: null,
    homePageLoading: true,
    expanded: []
  }),
  methods:{
    loadData: function () {
      var that = this;
      that.homePageLoading = true;      
      that.newInvoices = null;
      that.welcomeMessage = null;
      that.messages = null;
      that.messagesHome = null;

      var requests = [];
      requests.push(services.apiCallerInvoice.getNewInvoices(that.$cod_client.value));
      requests.push(services.apiCallerCustomer.home(that.$cod_client.value));
      requests.push(services.apiCallerMessage.getNewMessages(that.$cod_client.value));
      requests.push(services.apiCallerMessage.getMessagesHome(that.$cod_client.value));

      Promise.all(requests)
        .then(responses => {
          that.newInvoices = responses[0].data;
          that.welcomeMessage = responses[1].data;
          that.messages = responses[2].data;
          that.messagesHome = responses[3].data;
          that.expanded = Array.from(Array(that.messagesHome.length).keys());
        })
        .catch(error => {
          console.log(error);
          that.$refs.mySnackbar.open(that.$t("message.home_error"));
        })
        .finally(() => {
          that.homePageLoading = false;
        });
    }
  },
  created: function () {
    if(this.$cod_client.value) {
      this.loadData();
    }  
    this.$root.$on("codClientChanged", this.loadData);
  }
}
</script>

<style scoped>
.border{
    border:2px #E8EAF6 solid
}
</style>
